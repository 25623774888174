import React, { useState, useEffect } from "react";

import "../../../css/parts/Alert.css";

export default function Message({status = 1, message}) {

  // const [isActive, setIsActive] = useState(0);

  const RemoveAlert = () => {
    // setIsActive(0)
    window.location.reload();
  }

  // useEffect(() => {
  //   setIsActive(message.length >= 1 ? 1 : 0);
  //   console.log(message.length);
  // }, [message.length]);

  // console.log(isActive);
  // console.log(message.length);

  return (
    <div className={message ? 'alert-wrapper' : 'alert-wrapper hidden'}>
        <div className={status ? 'alert success' : 'alert danger'}>
          <span>{message}</span>
          <button onClick={RemoveAlert}>X</button>
        </div>
    </div>
  );
}
