import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import MealIcon from '../../../../assets/img/cat-icons/meal.svg';
import MedicineIcon from '../../../../assets/img/cat-icons/medicine.svg';
import SupportIcon from '../../../../assets/img/cat-icons/support.svg';
import StoresIcon from '../../../../assets/img/cat-icons/stores.svg';
import SportBeautyIcon from '../../../../assets/img/cat-icons/sport.svg';
import TourismIcon from '../../../../assets/img/cat-icons/tourism.svg';

import RightArrGreen from '../../../../assets/img/icons/right-arrow.svg'

export default function InnerNavigation() {

    const [extraNav, setExtraNav] = useState(false);

    const moreMenu = [
        {
            id: 3,
            url: '/ka-ge/organizations/3?pg=1',
            activity_category: window.lang.Finance,
        },
        {
            id: 9,
            url: '/ka-ge/organizations/9?pg=1',
            activity_category: window.lang.ImportExport,
        },
        {
            id: 12,
            url: '/ka-ge/organizations/12?pg=1',
            activity_category: window.lang.EducationScience,
        },
        {
            id: 16,
            url: '/ka-ge/organizations/16?pg=1',
            activity_category: window.lang.Buildingrepairs,
        },
        {
            id: 19,
            url: '/ka-ge/organizations/19?pg=1',
            activity_category: window.lang.Production,
        },
        {
            id: 26,
            url: '/ka-ge/organizations/26?pg=1',
            activity_category: window.lang.CultureArt,
        },
        {
            id: 27,
            url: '/ka-ge/organizations/27?pg=1',
            activity_category: window.lang.FarmingLivestock,
        },
        {
            id: 20,
            url: '/ka-ge/organizations/20?pg=1',
            activity_category: window.lang.CarTransport,
        },
        {
            id: 21,
            url: '/ka-ge/organizations/21?pg=1',
            activity_category: window.lang.StateNon,
        },
        {
            id: 22,
            url: '/ka-ge/organizations/22?pg=1',
            activity_category: window.lang.CommunicationMassMedia,
        },
        {
            id: 25,
            url: '/ka-ge/organizations/25?pg=1',
            activity_category: window.lang.Drinks,
        }
    ]
    const firstRow = moreMenu.slice(0, 4);
    const secondRow = moreMenu.slice(4, 8);
    const thirdRow = moreMenu.slice(8, 12);

    return (
        <nav className="midSection__navigation__wrapper">
            <div className='midSection__secondary__wrapper'>
                <ul className='mid__navigation__list'>
                <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/organizations/4?pg=1" className='mid__navigation__item'>
                            <img src={MealIcon} alt="" />
                            <span>{window.lang.MealsFun}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/organizations/23?pg=1" className='mid__navigation__item'>
                            <img src={MedicineIcon} alt="" />
                            <span>{window.lang.MedicinePharmacy}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/organizations/18?pg=1" className='mid__navigation__item'>
                            <img src={SupportIcon} alt="" />
                            <span>{window.lang.Services}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/organizations/8?pg=1" className='mid__navigation__item'>
                            <img src={StoresIcon} alt="" />
                            <span>{window.lang.Merchants}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/organizations/24?pg=1" className='mid__navigation__item'>
                            <img src={SportBeautyIcon} alt="" />
                            <span>{window.lang.Sportbeauty}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/organizations/6?pg=1" className='mid__navigation__item'>
                            <img src={TourismIcon} alt="" />
                            <span>{window.lang.TourismHolidaysN}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper mid__navigation__item__other">
                        <Link to="#" className='mid__navigation__item ' onClick={() => { setExtraNav(!extraNav) }}>
                            <span>{window.lang.More}</span>
                            <img className={extraNav ? "more more--active" : "more"} src={RightArrGreen} alt="" />
                        </Link>
                    </li>
                </ul>
                <div className={extraNav ? `mid-section__additional-nav flex` : 'mid-section__additional-nav'}>
                    <div className='additional-nav-row'>
                        {firstRow.map((itm, i) => {
                            return (<Link key={itm.id} to={itm.url}><div className="additiona-nav__item">{itm.activity_category}</div></Link>)
                        })}
                    </div>
                    <div className='additional-nav-row'>
                        {secondRow.map((itm, i) => {
                            return (<Link key={itm.id} to={itm.url}><div className="additiona-nav__item">{itm.activity_category}</div></Link>)
                        })}
                    </div>
                    <div className='additional-nav-row'>
                        {thirdRow.map((itm, i) => {
                            return (<Link key={itm.id} to={itm.url}><div className="additiona-nav__item">{itm.activity_category}</div></Link>)
                        })}
                    </div>
                </div>
            </div>
        </nav>
    )
}
