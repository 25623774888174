import React, { useState } from "react";
import { Link, Redirect, NavLink } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";

import logo from "../../../../assets/img/icons/logo.svg";
import blackX from "../../../../assets/img/black-x.png";

function Login({ setLogin, setPysicalReg, setRegWindow }) {
  // registration pysical
  const setReg = () => {
    setLogin(false);
    setPysicalReg(true);
  };

  // login
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [authStatusDesc, setAuthStatusDesc] = useState('');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const postLogin = async e => {
    e.preventDefault();

    await axios
      .get("/api/?app=user/Auth", {
        params: {
          email: email,
          password: password
        },
        timeout: 10000,
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(result => {
        if (result.status === 200) {
          if (result.data.status === 1) {
            if (result.data.user) {
              setLoggedIn(true);
            }
          } else {
            setIsError(true);
          }
        } else {
          setIsError(true);
        }
        setAuthStatusDesc(result.data.desc);
      })
      .catch(e => {
        setIsError(true);
      });
  };

  const browserHistoryBack = () => {
    window.history.back();
  }

  if (isLoggedIn) {
    return <Redirect to="/ka-ge/user" />;
  }

  return (
    <div className="login-window_wrapper">
      <div className="login-header">
        <div>
          <div className="main__header__navigation__logo">
            <NavLink
              to="/ka-ge/organizations"
              className="main__header__navigation__logo__content"
            >
              <img src={logo} alt="" />
            </NavLink>
          </div>
        </div>
        <p onClick={browserHistoryBack} className="login-window_close">
          <img src={blackX} alt="" />
        </p>
      </div>
      <div className="login-greeting">
        <h2>{window.lang.hi}</h2>
        <h4>{window.lang.pleaseAuth}</h4>
      </div>
      <form className="login-form">
        <div className="form-control">
          <label htmlFor="login-email">{window.lang.Email}</label>
          <input
            type="email"
            value={email}
            id="login-email"
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="form-control">
          <label htmlFor="login-password">{window.lang.password}</label>
          <input
            type="password"
            value={password}
            id="login-password"
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
        </div>
      </form>
      <div className="login-window_footer">
        <button onClick={postLogin} className="sign-in" type="button">
          {window.lang.Authorization}
        </button>
        {/* <a href="" className="forgot-password">
          {window.lang.Forgotpassword}
        </a> */}
      </div>
      {isError ? (
        <span className="auth-error">{authStatusDesc}</span>
      ) : null}
      <div className="login-registartion-content">
        <p>
          {window.lang.Donothaveaccount}{" "}<br></br>
          <Link to="/ka-ge/registration" id="signup">
            {window.lang.Signupnow}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
