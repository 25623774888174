import React, { useState, useEffect } from "react";

import Header from "../globalParts/Header";
import MidSection from "./parts/MidSection";
import OrganizationItem from "../../js/organizations/parts/OrganizationItem";
import MobileNavigation from "../globalParts/MobileNavigation/MobileNavigation";

export default function UserPage() {
  const [votes, setVotes] = useState(null);
  const [bookmarks, setBookmarks] = useState(null);
  const [user, setUser] = useState(null);

  window.scrollTo(0, 0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const fetchUserData = await fetch(
      `/api/?app=profile/ProfileApi&resource=mainPage`
    );
    const userData = await fetchUserData.json();
    const user = userData.user;
    setUser(user);
    if(user){
      setVotes(userData.data.votes);
      setBookmarks(userData.data.bookmark);
    } else {
      window.location.replace('/')
    }
  };
 
  let votesData = [];
  for(let x in votes && votes) {
    votesData.push(votes[x]);
  }

  console.log(bookmarks);

  let bookmarkData = [];
  for(let y in votes && bookmarks) {
    bookmarkData.push(bookmarks[y]);
  }

  return (
    <div className="user-page_container">
      <Header user={user ? user : null} />
      <MidSection user={user ? user : null} />
      
      {/* bookmarks */}
      {bookmarkData.length >=1 ? (
      <div className="organization-render-container--wrapper">
        <div className="organization-render__container">
          <div className='organization-render__header'>
              <span>{window.lang.BookmarkedData}</span>
          </div>
          <div className="organization-content-wrapper">
            {bookmarkData &&
              bookmarkData.map(organization => {
                return (
                  <OrganizationItem
                    key={organization.id}
                    organization={organization}
                  />
                );
              })}
          </div>
        </div>
      </div>
      ) : (
      <div className="organization-render-container--wrapper">
        <div className="organization-render__container">
          <div className='organization-render__header'>
              <span>{window.lang.BookmarkedData}</span>
          </div>
            <span className="not-found-data">{window.lang.notFound}</span>
        </div>
      </div>
      )}
     
      {/* votes */}
      {votesData.length >=1 ? (
      <div className="organization-render-container--wrapper">
        <div className="organization-render__container">
          <div className='organization-render__header'>
              <span>{window.lang.MyVotes}</span>
          </div>
          <div className="organization-content-wrapper">
            {votesData &&
              votesData.map(organization => {
                return (
                  <OrganizationItem
                    key={organization.id}
                    organization={organization}
                  />
                );
              })}
          </div>
        </div>
      </div>
      ) : (
        <div className="organization-render-container--wrapper">
          <div className="organization-render__container">
            <div className='organization-render__header'>
                <span>{window.lang.MyVotes}</span>
            </div>
              <span className="not-found-data">{window.lang.notFound}</span>
          </div>
        </div>
      )}

      <MobileNavigation />
    </div>
  );
}
