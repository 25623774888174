import React, { useState, useEffect } from "react";

import ArrowDown from "../../../../assets/img/arrow-wt.png";
import SearchIcon from "../../../../assets/img/icons/search.png";
import Loader from "react-loader-spinner";
import StreetItem from "./StreetItem";
import "../../../css/streetsPage/streets.css";

export default function MidSection({
  countries,
  cityId,
  cities,
  districtId,
  districts,
  streets,
  countryId,
  country,
  countryPhone,
  city,
  district,
  setCountry,
  setCountryPhone,
  setCity,
  setDistrict,
  setCountryId,
  setCityId,
  setDistrictId,
  isStrLoaded,
}) {
  const [showCountries, toggleShowCountries] = useState(false);
  const [showCities, toggleShowCities] = useState(false);
  const [showDistricts, toggleShowDisctricts] = useState(false);
  const [searchActive, toggleSearch] = useState(false);
  const [cityCode, setCityCode] = useState();

  const [filteredData, setFilteredData] = useState(null);

  const [filteredCountries, setFilteredCountries] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);

  useEffect(() => {
    // console.log(countries)
  }, [countries]);

  const filterData = async searchString => {
    let filteredArray = [];
    if (searchString.length >= 3) {
      let apiURL = '';
      if(districtId){
        apiURL = `/api/?app=streets/StreetsApi&resource=getSeacrhedStreets&country_id=${countryId}&town_id=${cityId}&town_district_id=${districtId}&street_name=${searchString}`
      } else {
        apiURL = `/api/?app=streets/StreetsApi&resource=getSeacrhedStreets&country_id=${countryId}&town_id=${cityId}&street_name=${searchString}`
      }
      let fetchItem = await fetch(apiURL);
      fetchItem.json().then(result => {
        return setFilteredData(result.streets);
      });
    } else {
      return setFilteredData(null);
    }
  };

  const filterCountries = str => {
    if (str.length >= 3) {
      let filteredData = countries.filter(country =>
        country.name.includes(`${str}`)
      );
      setFilteredCountries(filteredData);
    } else {
      setFilteredCountries(countries);
    }
  };
  const filterCities = str => {
    if (str.length >= 2) {
      let filteredData = cities.filter(city => {
        return isNaN(str)
          ? city.name != null
            ? city.name.includes(`${str}`)
            : 0
          : city.town_new_code != null
          ? city.town_new_code.includes(`${str}`)
          : 0;
      });
      setFilteredCities(filteredData);
    } else {
      setFilteredCities(cities);
    }
  };

  const filterDistricts = str => {
    if (str.length >= 2) {
      let filteredData = districts.filter(district =>
        district.name.includes(`${str}`)
      );
      setFilteredDistricts(filteredData);
    } else {
      setFilteredDistricts(districts);
    }
  };

  useEffect(() => {
  }, [filteredCities]);

  useEffect(() => {
    setFilteredCountries(countries);
    setFilteredCities(cities);
    setFilteredDistricts(districts);
  }, [countries, cities, districts]);

  useEffect(() => {
    if (showCountries) {
      let countryInput = document.getElementById("country-search-input");
      countryInput.focus();
    }
  }, [showCountries]);
  useEffect(() => {
    if (showCities) {
      let cityInput = document.getElementById("city-search-input");
      cityInput.focus();
    }
  }, [showCities]);
  useEffect(() => {
    if (showDistricts) {
      let districtInput = document.getElementById("district-search-input");
      districtInput.focus();
    }
  }, [showDistricts]);
  useEffect(() => {
    if (searchActive) {
      let streetInput = document.getElementById("street-search-input");
      streetInput.focus();
    }
  }, [searchActive]);

  return (
    <div className="streets-mid-container">
      <div className="streets-mid-bg">
        <div className="streets-label">{window.lang.StreetsZIPcodes}</div>
      </div>
      <div className="streets-content-wrapper">
        <div className="pick-place">
          <div className="pick-country">
            <label htmlFor="country-items-wrapper">
            </label>
            <div className="country-items-container">
              <div
                id="country-items-wrapper"
                onClick={() => {
                  toggleShowCountries(!showCountries);
                }}
              >
                <span className="country-item-name">{country}</span>
                <img src={ArrowDown} alt="" />
              </div>
              <div
                className={
                  showCountries
                    ? `country-items-list-wrapper list-visible`
                    : `country-items-list-wrapper`
                }
              >
                <div className="country-items-search">
                  <input
                    id="country-search-input"
                    placeholder={window.lang.Findcountry}
                    type="text"
                    onChange={e => filterCountries(e.target.value)}
                  />
                </div>
                {filteredCountries &&
                  filteredCountries.map(e => {
                    return (
                      <div
                        key={e.country_id}
                        className="country-item"
                        onClick={() => {
                          setCountry(e.name);
                          setCountryPhone(e.phone_code);
                          setCountryId(e.country_id);
                          toggleShowCountries(false);
                        }}
                      >
                        {e.name}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="pick-city">
            <label htmlFor="city-items-wrapper">
            </label>
            <div className="city-items-container">
              <div
                id="city-items-wrapper"
                onClick={() => toggleShowCities(!showCities)}
              >
                <span className="city-item-name">
                  {city ? (
                    city == 'თბილისი' && window.lang.Locale == 'en'  ? 'Tbilisi' : city
                  ):(
                    null
                  )} {cityCode ? `(${cityCode})` : null}
                </span>
                <img src={ArrowDown} alt="" />
              </div>
              <div
                className={
                  showCities
                    ? `city-items-list-wrapper list-visible`
                    : `city-items-list-wrapper`
                }
              >
                <div className="city-items-search">
                  <input
                    id="city-search-input"
                    placeholder={window.lang.FindCity}
                    type="text"
                    onChange={e => {
                      filterCities(e.target.value);
                    }}
                  />
                </div>
                {filteredCities &&
                  filteredCities.map(e => {
                    return (
                      <div
                        key={e.id}
                        className="city-item"
                        onClick={() => {
                          setCity(e.name);
                          setCityId(e.town_id);
                          setCityCode(e.town_new_code.replace(";", ""));
                          toggleShowCities(false);
                        }}
                      >
                        {e.name}{" "}
                        {e.town_new_code &&
                          `(${e.town_new_code.replace(";", "")})`}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="pick-district">
            <label htmlFor="district-items-wrapper">
              {/* {window.lang.Choosedistrict} */}
            </label>
            <div className="district-items-container">
              <div
                id="district-items-wrapper"
                onClick={() => {
                  toggleShowDisctricts(!showDistricts);
                }}
              >
                <span className="district-item-name">{district}</span>
                <img src={ArrowDown} alt="" />
              </div>
              <div
                className={
                  showDistricts
                    ? `district-items-list-wrapper list-visible`
                    : `district-items-list-wrapper`
                }
              >
                <div className="district-items-search">
                  <input
                    id="district-search-input"
                    placeholder={window.lang.Searcharea}
                    type="text"
                    onChange={e => {
                      filterDistricts(e.target.value);
                    }}
                  />
                </div>
                {filteredDistricts &&
                  filteredDistricts.map(e => {
                    return (
                      <div
                        key={e.id}
                        className="city-item"
                        onClick={() => {
                          setDistrict(e.name);
                          setDistrictId(e.town_district_id);
                          toggleShowDisctricts(false);
                        }}
                      >
                        {e.name}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="streets-content-right">
        <div className="search-street-wraper">
          <img src={SearchIcon} alt="" />
          <input
              id="street-search-input"
              type="text"
              className="street-search-input-head"
              placeholder={window.lang.Findstreet}
              onChange={e =>filterData(e.target.value)}
            />
          </div>
          <div className="streets-content-right">
            <div className="content-right-items">
              {isStrLoaded ? (
                filteredData ? (
                  filteredData.map((e, index) => {
                    return (
                      e.name ? (
                        <StreetItem key={index} streetItem={e} />
                      ) : (
                        ''
                      )
                    );
                  })
                ) : (
                  streets &&
                  streets.map((e, index) => {
                    return (
                      e.name ? (
                        <StreetItem key={index} streetItem={e} />
                      ) : (
                        ''
                      )
                    );
                  })
                )
              ) : (
                <div className="loader-container">
                  <Loader
                    type="ThreeDots"
                    color="#somecolor"
                    height={80}
                    width={80}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
