import React, { useEffect, useState } from "react";
import Header from "../globalParts/Header";
import TransportPageRender from "../transportPage/parts/TransportPageMidRender";
import MobileNavigation from "../globalParts/MobileNavigation/MobileNavigation";

export default function TransportPage() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = async () => {
    const fetchitem = await fetch(
      `/api/?app=afisha/AfishaApi&resource=events&showId=`
    );

    const item = await fetchitem.json();
    setUser(item.user);
  };

  return (
    <div className="event-page_container">
      <Header user={user ? user : null} />
      <TransportPageRender />
      <MobileNavigation />
    </div>
  );
}
