import React from "react";

import "../../../css/userPage/UserPage.css";

export default function MidSection({ user }) {
  const logout = async () => {
    const fetchData = await fetch(`/api/?app=user/Logout`);
    const item = fetchData.json();
    window.location.reload(true);
  };

  return (
    <div className="user-mid-container">
      <div className="user-mid-bg">
        <div className="user-mid-section-content">
          <div className="user-label">{window.lang.Welcome} {user && user.fname}</div>
          <button className="user-close-button" onClick={logout}>{window.lang.logout}</button>
        </div>
        <div className="dimmed-overlay"></div>
      </div>
    </div>
  );
}
