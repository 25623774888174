import React from 'react';
import OrganizationIcon from '../../../../assets/img/icons/org.svg';
import OrganizationSmIcon from "../../../../assets/img/icons/org2.png";

import WithoutImage from '../../../../assets/img/org-no-picture-block.jpg';

export default function organizationItem({organization, user}) {

    const redirectToOrg = () => {
        window.location.href = `/ka-ge/organization/${organization.id}`;
    }

    // start rating
    let ActiveStars = Array(organization.rate_score && parseInt(organization.rate_score));
    let InactiveStars =  organization.rate_count == 0 ? Array(5) : Array(organization.rate_score && 5-parseInt(organization.rate_score));

    return (
        <div className="organization-list__wrapper" onClick={() => redirectToOrg()}>
            <div className='organization-list-image__wrapper' style={{backgroundImage: `url(` + (organization.logo ? 'https://cdn.08.ge/logos/' + organization.logo : WithoutImage) + ')'}}>
                <div className='organization-list-image'  />
            </div>
            <div className='organization-list__header__wrapper'>
                <div className='organization-title'>
                    <img src={organization.parent == 0 ? OrganizationIcon : OrganizationSmIcon} alt="" />
                    <a className='organization-list-name' title={organization.name}>{organization.name}</a>
                </div>
                <div className='organization-description out'>{organization.activity}</div>
                <div className="organization-rating-content">
                    <div className="organization-rating-stars star-single ">
                        { organization.rate_count == 0 ? (
                            ''
                        ) : (
                            Array.from(ActiveStars, (e, index) => {
                                return (
                                    <span key={index}>★</span>
                                )
                            })
                        )}
                                                 
                        {
                            Array.from(InactiveStars, (e, index) => {
                                return (
                                    <span key={index} className="star_dis">★</span>
                                )
                            })
                        }							
                    </div>
                </div>
            </div>
        </div>
    )
}
