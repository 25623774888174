import React from "react";

import InnerNavigation from "./InnerNavigation";

export default function MidSection() {

  return (
    <div className="midSection__container">
      <InnerNavigation />
    </div>
  );
}
