import React from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import MidSliderItem from './MidSliderItem';

export default function MidSlider({ organizations, user }) {

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
    };

    return (
        <div className="organization-render__container">
            <div className='organization-render__header'>
                <span>{window.lang.Popularorganizations}</span>
            </div>
            <div className='organization-render__wrapper'>
                <Carousel responsive={responsive}>
                    {organizations && organizations.map((organization) => {
                        return (
                            <MidSliderItem key={organization.id} user={user} organization={organization} />
                        );
                    })}
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </Carousel>
            </div>
        </div>
    )
}
