import React, { useState } from "react";
import RepeatIco from "../../../../assets/img/icons/repeat.png";

import MoreIcon_B from "../../../../assets/img/icons/arr-menu-b.svg";
import MoreIcon_W from "../../../../assets/img/icons/arr-menu-w.svg";
import PinIcon from "../../../../assets/img/map-pin.png";

import CloseIcon from "../../../../assets/img/x-icon.png";

export default function StreetItem({ streetItem }) {
  const [moreActive, setMoreActive] = useState(false);

  return (
    <div
      className={
        moreActive
          ? "content-right-item content-right-item--active"
          : "content-right-item"
      }
    >
      <div
        className={
          moreActive
            ? "street-item-basic-info street-item-basic"
            : "street-item-basic-info"
        }
        onClick={() => setMoreActive(!moreActive)}
      >
        <img className="street-item-more-btn" src={moreActive ? MoreIcon_W : MoreIcon_B} alt="" />
        <div className="content-item-left">
          {streetItem.name ? streetItem.name : null}
        </div>
        {/* <div className="content-item-center">
        {streetItem.name_old ? streetItem.name_old : null}
      </div>
      <div className="content-item-center">
        {streetItem.district_name ? streetItem.district_name : null}
      </div> */}
        {moreActive ? (
          <div className="content-item-center">
            {" "}
            {streetItem.district_name ? streetItem.district_name : null}
          </div>
        ) : (
          ''
          // <img className="street-item-pin" src={PinIcon} alt="" />
        )}
        <div className={moreActive ? "content-item-right content-item-right--active" : "content-item-right"}>
          <div className={moreActive ? "item-post-code item-post-code--active" : "item-post-code"}>
            {moreActive ? (
              <img src={CloseIcon} alt=''></img>
            ) : (
              <span>{streetItem.zip ? streetItem.zip : null}</span>
            )}
            {/* <img src={RepeatIco} alt="" /> */}
          </div>
        </div>
      </div>
      {moreActive && (
        <div className="street-item-more-info">
          <span>{window.lang.Oldname}</span>
          <span className='street-item-old-name'>
            {/* {streetItem.name_old ? streetItem.name_old : window.lang.notFound} */}
            <div dangerouslySetInnerHTML={{ __html:  streetItem.name_old ? streetItem.name_old : window.lang.notFound}} />
          </span>
          {streetItem.district_description ? (
              <span className='street-item-old-name'>
                <br/>
                {streetItem.district_description}
              </span>
          ) : (
              ''
          )}
          <p class="street-item-zip-info">
            <div dangerouslySetInnerHTML={{ __html:  streetItem.mark}} />
          </p>
        </div>
      )}
    </div>
  );
}
