import React, { useState, useEffect } from "react";
import SearchIcon from "../../../../../assets/img/icons/search.svg";
import NavIcon from "../../../../../assets/img/icons/placeholder.svg";
import CloseIcon from "../../../../../assets/img/icons/close.png";
import OrganizationIcon from "../../../../../assets/img/icons/org.svg";
import OrganizationSmIcon from "../../../../../assets/img/icons/org2.png";

import { Link } from "react-router-dom/cjs/react-router-dom";

export default function MidSection({categoryID}) {

  const [searchActive, setSearchActive] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchNameStr, setSearchNameStr] = useState("");
  const [searchPlaceStr, setSearchPlaceStr] = useState("");
  const [searchCityStr, setSearchCityStr] = useState(51063);

  let categoryName = '';
  switch(categoryID) {
    case '4':
        categoryName = window.lang.MealsFun;
      break;
    case '23':
        categoryName = window.lang.MedicinePharmacy;
      break;
    case '18':
        categoryName = window.lang.Services;
      break;
    case '8':
        categoryName = window.lang.Merchants;
      break;
    case '24':
        categoryName = window.lang.Sportbeauty;
      break;
    case '6':
        categoryName = window.lang.TourismHolidaysN;
      break;
    default:
        categoryName = window.lang.Organizations;
  }

  useEffect(() => {
    if (searchNameStr.length >= 3 || searchPlaceStr >= 3) {
      fetchSearchData();
    } else {
      setSearchData([]);
    }
  }, [searchNameStr, searchPlaceStr, searchCityStr]);

  const fetchSearchData = async () => {
    const fetchItem = await fetch(
      `/api/?app=organizations/OrganizationApi&resource=searchByString&str_name=${searchNameStr}&str_addrs=${searchPlaceStr}&is_city=${searchCityStr}`
    );
    const item = await fetchItem.json();
    setSearchData(item.data);
  };

  const searchOrganizations = e => {
    e.preventDefault();
    if (searchActive) {
      let searchContent = `&str_name=${searchNameStr}&str_addrs=${searchPlaceStr}&is_city=${searchCityStr}`;
      window.location.href = `/ka-ge/organizations/search/${searchContent}`;
    } else {
      setSearchActive(true);
    }
  };

  const removePlaceHolder = () => {
    document.getElementById('search-field-orgPlace').placeholder = "";
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else { 
      console.log("Geolocation is not supported by this browser.");
    }
  }
  
  const showPosition = (position) => {
    // console.log(position.coords.latitude +  position.coords.longitude);
    let searchContent = `&str_name=${searchNameStr}&str_addrs=${searchPlaceStr}&is_city=${searchCityStr}&latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`;
    window.location.href = `/ka-ge/organizations/search/${searchContent}`;
  }

  return (
    <div className="midSection__container">
      <div className="midSection__search-panel">
        {/* <span>{window.lang.Organizations}</span> */}
        <span>{categoryName }</span>
        <form
          className={
            searchActive ? "search-field search-field-active" : "search-field"
          }
        >
          <div className="search-field-container">
            <div className="search-field-wrapper">
              <button
                type="submit"
                className="submit search-field-submit"
                onClick={e => searchOrganizations(e)}
              >
                <img src={SearchIcon} alt="" />
              </button>
              {searchActive && (
                <input
                  pattern=".{3,}"
                  type="text"
                  placeholder={window.lang.OrganizationName}
                  title="3 characters minimum"
                  autoComplete="off"
                  required
                  min="3"
                  onChange={e => {
                    setSearchNameStr(e.target.value);
                  }}
                  className="search-field-input"
                />
              )}
              {searchActive && (
                <button
                  className="filter"
                  onClick={e => {
                    e.preventDefault();
                    setSearchActive(false);
                  }}
                >
                  <img src={CloseIcon} alt="" />
                </button>
              )}
            </div>
            {searchActive && (
            <div className="live-search-wrapper">
              {searchData.map(e => {
                return (
                  <Link
                    key={e.id}
                    to={`/ka-ge/organization/${e.id}`}
                    className="live-search-item"
                  >
                    <img
                      src={
                        e.parent == 0 ? OrganizationIcon : OrganizationSmIcon
                      }
                      alt=""
                    />
                    <div className="live-search-item-info">
                      <span className="live-search-item-name">{e.name}</span>
                      <p className="live-search-item-address">{e.address}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
             )}
          </div>
        </form>
      </div>
      {searchActive && (
        <div className="search-field-additional">
          <div className="horizontal-div">
            <button
                  type="submit"
                  className="submit search-field-submit search-field-address"
                  onClick={e => getLocation(e)}
                >
                  <img src={NavIcon} alt="" />
              </button>
            <input
              id="search-field-orgPlace"
              pattern=".{3,}"
              type="text"
              placeholder={window.lang.Locationorganization}
              title="3 characters minimum"
              autoComplete="off"
              onChange={e => {
                setSearchPlaceStr(e.target.value);
              }}
              onFocus={e => removePlaceHolder()}
            />
            <select
              id="city-select-orgCity"
              className="city-select"
              onChange={e => {
                setSearchCityStr(e.target.value);
              }}
            >
              <option value="0" hidden >{window.lang.Cities}</option>
              <option value="0">{window.lang.All}</option>
              <option value="51063" selected="selected">{window.lang.Tbilisi}</option>
              <option value="51043">{window.lang.Batumi}</option>
              <option value="51549">{window.lang.Kutaisi}</option>
              <option value="53447">{window.lang.Rustavi}</option>
              <option value="53767">{window.lang.Mtskheta}</option>
              <option value="53666">{window.lang.Gori}</option>
              <option value="53073">{window.lang.Zugdidi}</option>
              <option value="56088">{window.lang.Poti}</option>
              <option value="50970">{window.lang.Zestafoni}</option>
              <option value="53952">{window.lang.Khashuri}</option>
              <option value="50527">{window.lang.Telavi}</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
}
