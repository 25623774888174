import React, { useEffect, useState } from "react";
import MainHeader from "../globalParts/Header";
import MidSection from "./parts/MidSection";
import Axios from "axios";
// import MobileNavigation from "../globalParts/MobileNavigation/MobileNavigation";

function HomePage() {
  const [dataToReturn, setDataToReturn] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    Axios.get("/api/?app=organizations/OrganizationApi", {
      params: {
        resource: "homePage"
      },
      timeout: 10000,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then(res => {
      setDataToReturn(res.data.data);
      setUser(res.data.user);
    });
  }, []);
  return (
    <div className="main__page__container">
      <MainHeader user={user ? user : null} />
      <div className="page-title-centered">
        <h5>{window.lang.MainPageTitleSub}</h5>
        {/* <p>{window.lang.MainPageTitleSub}</p> */}
      </div>
      <MidSection dataToReturn={dataToReturn} />
      {/* <MobileNavigation /> */}
    </div>
  );
}

export default HomePage;
