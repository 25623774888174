import React from 'react';
import CallIcon from '../../../../assets/img/icons/phone.svg';
import LocationIcon from '../../../../assets/img/icons/pin.svg';
import OrganizationIcon from '../../../../assets/img/icons/org.svg';
import OrganizationSmIcon from "../../../../assets/img/icons/org2.png";

import WithoutImage from '../../../../assets/img/org-no-picture-block.jpg';
import { Link } from 'react-router-dom';

import MenuIco from '../../../../assets/img/icons/menu.svg';
import DeliveryIco from '../../../../assets/img/icons/box.svg';
import bookmark from '../../../../assets/img/icons/bookmark.svg';


export default function MidSliderItem({organization, user}) {

    var url_string = window.location.href;
    var url = new URL(url_string);

    const redirectToOrg = () => {
        window.location.href = `/ka-ge/organization/${organization.id}`;
    }

    const onBookmarkClick = async(e) => {
        e.preventDefault();
        if(user){
            const fetchItem = await fetch(`/api/?app=organizations/OrganizationApi&resource=setBookmark&org_id=${organization.id}`)
            const item = await fetchItem.json();
            if(item.status == 1) {
                alert(`${user.fname} ${window.lang.Thisorganizationfavorites}`);
            } else {
                // alert(`${user.fname} ${window.lang.Thisorganizationfavorites}`);
            }
        } else {
            alert(`${window.lang.Onlyuserscanremember}`)
        }
    }

      // start rating
	let ActiveStars = Array(organization.rate_score && parseInt(organization.rate_score));
	let InactiveStars =  organization.rate_count == 0 ? Array(5) : Array(organization.rate_score && 5-parseInt(organization.rate_score));

    return (
        <div className="organization__wrapper">
            <div className='organization__header__wrapper'>
                <div className='organization-title'>
                    <img src={organization.parent == 0 ? OrganizationIcon : OrganizationSmIcon} alt="" />
                    <Link className='organization-name' to={`/ka-ge/organization/${organization.id}`} title={organization.name}>{organization.name}</Link>
                    {/* <img src={bookmark} alt="" className="bookmark-btn" onClick={(e) => {onBookmarkClick(e)}} /> */}
                </div>
                <div className='organization-description out'>{organization.activity}</div>
                <div className="organization-rating-content">
                    <div className="organization-rating-stars star-single">
                        { organization.rate_count == 0 ? (
                            ''
                        ) : (
                            Array.from(ActiveStars, (e, index) => {
                                return (
                                    <span key={index}>★</span>
                                )
                            })
                        )}
                                                    
                        {
                            Array.from(InactiveStars, (e, index) => {
                                return (
                                    <span key={index} className="star_dis">★</span>
                                )
                            })
                        }							
                    </div>
                    <div className='organization-rating'>{organization.rate_count} {window.lang.Rate}</div>
                </div>
            </div>
            <div onClick={() => redirectToOrg()} className='organization-image__wrapper' style={{backgroundImage: `url(` + (organization.logo ? 'https://cdn.08.ge/logos/' + organization.logo : WithoutImage) + ')'}}>
                <div className='organization-image'  />
                <div className='organization-address'>
                    <img src={LocationIcon} alt="" />
                    <span>{organization.address}</span>
                </div>
            </div>

            <div className='organization__footer__wrapper'>
                <div className='organization-working-hours'>{organization.work_hours}</div>
                <div className='organization-icons'>
                    <div className='organization-menu-icon'>
                    {organization.has_delivery === 1 && <img src={MenuIco} alt=""/>}
                    </div>
                    <div className='organization-box-icon'>
                    {organization.has_menu === 1 && <img src={DeliveryIco} alt=""/>}
                    </div>
                    <div className='organization-phone-icon'>
                        <a className='call-icon__wrapper'>
                            <img src={CallIcon} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
