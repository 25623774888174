import React from "react";
import "../../../css/transportPage/transport.css";

export default function MidSection() {

  return (
    <div className="transport-mid-container">
      <div className="transport-mid-bg">
        <div className="transport-label">{window.lang.Transport}</div>
        <div className="dimmed-overlay"></div>
      </div>
      <div className="transport-content-wrapper">
        <h4>{window.lang.serviceunderconstruction}</h4>
      </div>
    </div>
  );
}
