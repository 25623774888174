import React from 'react';
import { Link } from 'react-router-dom';

import OrganizationsIcon from '../../../../assets/img/main/building.svg';
import TktIcon from '../../../../assets/img/main/bill.svg';
import TransportIcon from '../../../../assets/img/main/bus.svg';
import CurrencyIcon from '../../../../assets/img/main/exchanging.svg';
import StreetsIcon from '../../../../assets/img/main/location.svg';
import CalendarIcon from '../../../../assets/img/main/calendar.svg';
// import CalendarIcon from '../../../../assets/img/menu-icons/calendar-b.png';

export default function InnerNavigation() {

    return (
        <nav className="midSection__navigation__wrapper">
            <div className='midSection__secondary__wrapper'>
                <ul className='mid__navigation__list'>
                <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/organizations" className='mid__navigation__item'>
                            <img src={OrganizationsIcon} alt="" />
                            <span>{window.lang.Organizations}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/events" className='mid__navigation__item'>
                            <img src={TktIcon} alt="" />
                            <span>{window.lang.Afisha}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/transport" className='mid__navigation__item'>
                            <img src={TransportIcon} alt="" />
                            <span>{window.lang.Transport}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/currency" className='mid__navigation__item'>
                            <img src={CurrencyIcon} alt="" />
                            <span>{window.lang.Currency}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/streets" className='mid__navigation__item'>
                            <img src={StreetsIcon} alt="" />
                            <span>{window.lang.Streets}</span>
                        </Link>
                    </li>
                    <li className="mid__navigation__item__wrapper">
                        <Link to="/ka-ge/newcalendar" className='mid__navigation__item'>
                            <img src={CalendarIcon} alt="" />
                            <span>{window.lang.Calendar}</span>
                        </Link>
                    </li>
                </ul>
                
            </div>
        </nav>
    )
}
