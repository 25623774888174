import React, { useState, useEffect } from "react";

import "../../../../css/parts/ReviewItem.css";

export default function ReviewItem(review) {
  let rate_count = review && review['review'].rate;
  let review_id = Math.floor(Math.random() * 100);

  const toggleStars = () => {
    let starsToToggle = document.getElementsByClassName(
      `star-div-${review_id}`
    );
    let starsToToggleArr = Array.from(starsToToggle);
    if (starsToToggleArr.length > 0) {
      for (let i = 0; i < rate_count; i++) {
        starsToToggleArr[i].classList.add("star-active");
      }
    }
  };

  const renderStars = () => {
    let returnStars = [];
    for (let i = 0; i < 5; i++) {
      returnStars.push(
        <div className={`star-div star-div-${review_id}`}></div>
      );
    }
    return returnStars;
  };

  // start rating
  let ActiveStars = Array(parseInt(rate_count));
  let InactiveStars = Array(5-parseInt(rate_count));

  return (
    <div className="review-item-wrapper">
      <div className="review-count">
        <div className="reviewer-name">{review && review['review'].fname} {review && review['review'].lname}</div>
        <div className="review-stars">
            { 
              Array.from(ActiveStars, (e, index) => {
                return (
                  <span key={index}>★</span>
                )
              })
            }
                          
            {
              Array.from(InactiveStars, (e, index) => {
                return (
                  <span key={index} className="star_dis">★</span>
                )
              })
            }	
        </div>
        <div className="timeout-div">
          {setTimeout(() => {
            return toggleStars();
          }, 500)}
        </div>
      </div>
      <p className="review-content">
        {review && review['review'].description}
      </p>
    </div>
  );
}
