import React, { useEffect, useState } from "react";
import MainHeader from "../globalParts/Header";
import MidSection from "./parts/MidSection";
import MidSlider from "./parts/MidSlider";
import Axios from "axios";
import MobileNavigation from "../globalParts/MobileNavigation/MobileNavigation";

import "../../css/main-page.css";

function MainPage() {
  const [dataToReturn, setDataToReturn] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    Axios.get("/api/?app=organizations/OrganizationApi&resource=mainPage", {
      params: {
        resource: "mainPage"
      },
      timeout: 10000,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then(res => {
      setDataToReturn(res.data.data);
      setUser(res.data.user);
    });
  }, []);
  return (
    <div className="main__page__container">
      <MainHeader user={user ? user : null} />
      <MidSection dataToReturn={dataToReturn} />
      <MidSlider organizations={dataToReturn} user={user} />
      <MobileNavigation />
    </div>
  );
}

export default MainPage;
