const lang = {
    ge:{
        Locale: 'ge',
        Organizations:"ორგანიზაციები",
        Afisha:"აფიშა",
        Transport:"ტრანსპორტი",
        Currency:"ვალუტა",
        Streets:"ქუჩები",
        Calendar:"კალენდარი",
        Login:"შესვლა",
        SecularCalendar:"საერო კალენდარი",
        Churchdays:"საეკლესიო დღეები",
        ExchangeRate:"ვალუტის კურსი",
        NationalBank:"ეროვნული ბანკი",
        CommercialBanks:"კომერციული ბანკები",
        MarketRate:"საბაზრო კურსი",
        OfficialRate:"ოფიციალური კურსი",
        Buy:"ყიდვა",
        Sell:"გაყიდვა",
        SeeMore:"მეტის ნახვა",
        AllCurrencies:"ყველა ვალუტა",
        All:"ყველა",
        More:"სხვა",
        Back: 'უკან',

        AmiraniCinema:"კინოთეატრი ამირანი",
        RustaveliCinema:"კინოთეატრი რუსთაველი",
        CaveGallery:"კავეა გალერია",
        CaveaEastPoint:"კავეა ისთ ფოინთი",
        CaveCityMall:"კავეა სითი მოლი",

        Amirani:"ამირანი",
        Rustaveli:"რუსთაველი",
        Gallery:"გალერია",
        CaveTbilisiMall:"კავეა Tbilisi Mall",

        January:"იანვარი",
        February:"თებერვალი",
        March:"მარტი",
        April:"აპრილი",
        May:"მაისი",
        June:"ივნისი",
        July:"ივლისი",
        August:"აგვისტო",
        September:"სექტემბერი",
        October:"ოქტომბერი",
        November:"ნოემბერი",
        December:"დეკემბერი",

        Cinema:"ფილმი",
        Transport:"ტრანსპორტი",
        Kids:"საბავშვო",
        Opera:"ოპერა",
        railway:"რკინიგზა",
        Usedtickets:"მეორადი ბილეთები",
        Flights:"ფრენები",
        Theater:"თეატრი",
        Concert:"კონცერტი",
        Sport:"სპორტი",
        PopularMovie:"პოპულარული ფილმი",
        Populartheater:"პოპულარული სპექტაკლი",
        Or:"ან",

        Email:"ელ. ფოსტა",
        password:"პაროლი",
        repeatpassword :"გაიმეორეთ პაროლი",
        Authorization:"ავტორიზაცია",
        Forgotpassword:"დაგავიწყდა პაროლი?",
        checkdataaccuracy:"შეცდომა, გთხოვთ სრულად შეავსოთ მოცემული ველები.",
        SignupGoogle:"Google-ით რეგისტრაცია",
        Donothaveaccount:"არ გაქვთ ანგარიში?",
        Signupnow:"გაიარეთ რეგისტრაცია",
        RegistrationPersonal:"ფიზიკური პირის რეგისტრაცია",

        Name:"სახელი",
        surname:"გვარი",
        IagreeTermsConditions:"ვეთანხმები წესებსა და პირობებს",
        Toregister:"დარეგისტრირება",
        verificationcodesented:"ვერიფიკაციის კოდი გამოგზავნილია თქვენს ელ. ფოსტაზე!",
        Legalpersonalregistration:"იურიდიულ პირად რეგისტრაცია",

        StreetsZIPcodes:"ქუჩები / ZIP კოდები",
        Choosecountry:"აირჩიე ქვეყანა",
        Findcountry:"მოძებნეთ ქვეყანა",
        Choosecity:"აირჩიე ქალაქი",
        FindCity:"მოძებნეთ ქალაქი",
        Searcharea:"მოძებნეთ უბანი",
        Choosedistrict:"აირჩიე უბანი",
        Telephonecode:"სატელეფონო კოდი",
        StreetName:"ქუჩის დასახელება",
        Oldname:"ძველი დასახელება",
        ZIPcodes:"ZIP კოდები",

        popular:"პოპულარული",
        Finance:"ფინანსები",
        ImportExport:"იმპორტი, ექსპორტი",
        EducationScience:"განათლება, მეცნიერება",
        Buildingrepairs:"მშენებლობა, რემონტი",
        Production:"წარმოება",
        CultureArt:"კულტურა, ხელოვნება",
        FarmingLivestock:"მეურნეობა, მეცხოველეობა",
        CarTransport:"ავტომობილი, ტრანსპორტი",
        nongovernmental:"სახელმწიფო, არასამთავრობო",
        CommunicationMass:"კომუნიკაცია, მასმედია",
        Drinks:"სასმელები",

        MealsFun:"კვება, გართობა",
        MedicinePharmacy:"მედიცინა, ფარმაცია",
        Services:"მომსახურება",
        Merchants:"სავაჭრო ობიექტები",
        Sportbeauty:"სპორტი, სილამაზე",

        Numberemployees:"თანამშრომლების რაოდენობა",
        organizationwasfounded:"ორგანიზაციის დაფუძნების დრო",
        Open:"ღიაა",
        Menu:"მენიუ",
        DeliveryService:"მიტანის სერვისი",
        Allfilters:"ყველა ფილტრი",
        Viewresults:"შედეგების ნახვა",
        hedophis:"არის ჰედოფისი",
        CorporateInsurance:"კორპორატიული დაზღვევა",
        Website:"ვებგვერდი",
        From:"-დან",
        till:"-მდე",
        Activitiesorganization:"ორგანიზაციის საქმიანობა",

        distinct: "უბანი",
        OrganizationName: 'ორგანიზაციის სახელი',
        All: 'ყველა',

        Cities: 'ქალაქები',
        Tbilisi: 'თბილისი',
        Batumi: 'ბათუმი',
        Kutaisi: 'ქუთაისი',
        Rustavi: 'რუსთავი',
        Mtskheta: 'მცხეთა',
        Gori: 'გორი',
        Zugdidi: 'ზუგდიდი',
        Poti: 'ფოთი',
        Zestafoni: 'ზესტაფონი',
        Khashuri: 'ხაშური',
        Telavi: 'თელავი',

        Locationorganization: 'მისამართი / უბანი',
        Popularorganizations: 'პოპულარული ორგანიზაციები',
        TourismHolidaysN: 'ტურიზმი, დასვენება',
        Rate: 'შეფასება',
        Thisorganizationfavorites: 'ეს ორგანიზაცია შეინახა თქვენს ფავორიტებში',
        Onlyuserscanremember: 'მხოლოდ მომხმარებლებს შეუძლიათ ორგანიზაციის დამახსოვრება',

        Offer: 'კატეგორიები',
        DrrectorNumber: 'დირექტორის ნომერი',
        Discount: 'ფასდაკლება',
        CorporateMobile: 'კორპორატიული მობილური',
        Moreinformation: 'დამატებითი ინფორმაცია',

        MyVotes: 'შეფასებული ორგანიზაციები',
        Welcome: 'მოგესალმებით',
        BookmarkedData: 'შენახული ორგანიზაციები',
        notFound: 'არ მოიძებნა',
        Hide: 'დაფარულია',
        logout: 'გამოსვლა',

        pleaseEnterCorectInformation: 'გთხოვთ სწორად მიუთითოთ მონაცემები',
        gallery: 'გალერია',
        web: 'ვებ',
        Activitiesorganization: 'ორგანიზაციის საქმიანობა',
        workinghours: 'სამუშაო საათები',
        menu: 'მენიუ',
        DeliveryService: 'მიტანის სერვისი',
        Legalstatus: 'იურიდიული სტატუსი',
        Rest: 'დასვენების დღე',
        Withoutrest: 'დასვენების გარეშე',
        Unknown: 'უცნობი',
        Director: 'დირექტორი',
        Postalcode: 'საფოსტო ინდექსი',
        IdentificationCode: 'საიდენტიფიკაციო კოდი',
        Numbers: 'დამატებითი ნომრები:',
        Dateof: 'დაარსების თარიღი',
        Rate: 'შეფასება',
        Fivestar: 'ძალიან კარგი - 5 ვარსკვლავი',
        Forstar: 'საკმაოდ კარგი - 4 ვარსკვლავი',
        Threestar: '3 ვარსკვლავი',
        Twostar: '2 ვარსკვლავი',
        OneStar: '1 ვარსკვლავი',
        NoRatefound: 'შეფასებები არ მოიძებნა',
        similarOrg: 'მსგავსი კომპანიები',
        parentOrg: 'შვილობილი ორგანიზაციები / ფილიალები',
        Allcurrencies: 'ყველა ვალუტა',
        Searchphrase: 'საძიებო ფრაზა',
        Authorization: 'ავტორიზაცია',
        or: 'ან',

        Finance: 'ფინანსები',
        ImportExport: 'იმპორტი, ექსპორტი',
        EducationScience: 'განათლება, მეცნიერება',
        Buildingrepairs: 'მშენებლობა, რემონტი',
        Production: 'წარმოება',
        CultureArt: 'კულტურა, ხელოვნება',
        FarmingLivestock: 'მეურნეობა, მეცხოველეობა',
        CarTransport: 'ავტომობილი, ტრანსპორტი',
        StateNon: 'სახელმწიფო, არასამთავრობო',
        CommunicationMassMedia: 'კომუნიკაცია, მასმედია',
        Drinks: 'სასმელები',
        Yourrating: 'თქვენი შეფასება',
        submit: 'დამატება',
        Georgia: 'საქართველო',
        Findstreet: 'მოძებნეთ ქუჩა',

        Modules: 'მოდულები',
        Categories: 'კატეგორიები',
        Contactus: 'დაგვიკავშირდით',
        Downloadapp: 'გადმოწერეთ აპლიკაცია',
        ComingSoon: 'დროებით მიწვდომელია',
        serviceunderconstruction: 'სერვისი დამუშავების პროცესშია',
        
        Company: 'კომპანია',
        AboutUs: 'ჩვენს შესახებ',
        Direction: 'მიმართულება',
        DirectionTwo: 'მიმართულება/ Directions',
        Contact: 'კონტაქტი',
        toseemoreorg: 'მეტი ორგანიზაციის სანახავად გთხოვთ',

        hi: 'გამარჯობა',
        pleaseAuth: 'გთხოვთ გაიაროთ ავტორიზაცია',
        Moredetails: 'დამატებითი დეტალები',
        commerc: 'კომერციული',
        erv: 'ეროვნული',
        sabaz: 'საბაზრო კურსი',
        beta: 'სატესტო',

        MainPageTitle: 'მოძებნე ყველა სახის ინფორმაცია ერთ სივრცეში!',
        MainPageTitleSub: 'რომელი მოდულის \n ინფორმაციას ეძებთ?',

        WorkingHours: 'სამუშო საათები',

        // test: 'test',
        // {window.lang.test}
        // window.lang.test

    },
    en: {
        Locale: 'en',
        Organizations:"Organizations",
        Afisha:"Events",
        Transport:"Transport",
        Currency:"Currency",
        Streets:"Streets / Zip-Codes",
        Calendar:"Calendar",
        Login:"Log In",
        SecularCalendar:"Secular Calendar",
        Churchdays:"Church days",
        ExchangeRate:"Exchange rate",
        NationalBank:"National bank",
        CommercialBanks:"Commercial bank",
        MarketRate:"Market rate",
        OfficialRate:"Official rate",
        Buy:"Buy",
        Sell:"Sell",
        SeeMore:"More",
        AllCurrencies:"All currencies",
        All:"All",
        More:"More",
        Back: 'Back',

        AmiraniCinema:"Amirani cinema",
        RustaveliCinema:"Rustaveli cinema",
        CaveGallery:"Cavea gallery",
        CaveaEastPoint:"Cavea east point",
        CaveCityMall:"Cavea city mall",

        Amirani:"Amirani",
        Rustaveli:"Rustaveli",
        Gallery:"Gallery",
        CaveTbilisiMall:"Cavea Tbilisi Mall",

		January: "January",
		February: "February",
		March: "March",
		April: "April",
		May: "May,",
		June: "June",
		July: "July",
		August: "August",
		September: "September",
		October: "October",
		November: "November",
		December: "December",

        Cinema:"Cinema",
        Transport:"Transport",
        Kids:"Kids",
        Opera:"Opera",
        railway:"Railway",
        Usedtickets:"მეორადი ბილეთები",
        Flights:"Flights",
        Theater:"Theater",
        Concert:"Concert",
        Sport:"Sport",
        PopularMovie:"Popular movie",
        Populartheater:"Popular theater",
        Or:"Or",

        Email:"E-mail",
        password:"Password",
        repeatpassword :"Repeat password",
        Authorization:"Login",
        Forgotpassword:"Forgot password?",
        checkdataaccuracy:"Error, please check the validity of the data!",
        SignupGoogle:"Signup with Google",
        Donothaveaccount:"Don't have account?",
        Signupnow:"Signup now!",
        RegistrationPersonal:"Signup as Personal",

        Name:"First name",
        surname:"Last name",
        IagreeTermsConditions:"I'm agree to legal terms and conditions",
        Toregister:"Signup",
        verificationcodesented:"Verification code is sent to your E-mail!",
        Legalpersonalregistration:"Signup as Company",

        StreetsZIPcodes:"Street / ZIP Codes",
        Choosecountry:"Choose country",
        Findcountry:"Find country",
        Choosecity:"Choose city",
        FindCity:"Find city",
        Searcharea:"Find district",
        Choosedistrict:"Choose district",
        Telephonecode:"Phone code",
        StreetName:"Name of street",
        Oldname:"Old name",
        ZIPcodes:"ZIP Codes",

        popular:"Popular",
        Finance:"Finances",
        ImportExport:"Logistic",
        EducationScience:"Education, Science",
        Buildingrepairs:"Construction",
        Production:"Production",
        CultureArt:"Culture, art",
        FarmingLivestock:"Agriculture, livestock",
        CarTransport:"Transport",
        nongovernmental:"Government, NGO organizations",
        CommunicationMass:"Media",
        Drinks:"Drinks",

        MealsFun:"Food, Fun",
        MedicinePharmacy:"Pharmacy",
        Services:"Service",
        Merchants:"Malls",
        Sportbeauty:"Sport, Beauty",

        Numberemployees:"Number of employees",
        organizationwasfounded:"Creation date",
        Open:"Open",
        Menu:"Menu",
        DeliveryService:"Delivery service",
        Allfilters:"All filter",
        Viewresults:"View results",
        hedophis:"Is a Head Office",
        CorporateInsurance:"Corporate insurance",
        Website:"Webpage",
        From:"From",
        till:"Till",
        Activitiesorganization:"Field of activity of the organization",

        distinct: "distinct",
        OrganizationName: 'Organization Name',
        All: 'All',

        Cities: 'Cities',
        Tbilisi: 'Tbilisi',
        Batumi: 'Batumi',
        Kutaisi: 'Kutaisi',
        Rustavi: 'Rustavi',
        Mtskheta: 'Mtskheta',
        Gori: 'Gori',
        Zugdidi: 'Zugdidi',
        Poti: 'Poti',
        Zestafoni: 'Zestafoni',
        Khashuri: 'Khashuri',
        Telavi: 'Telavi',

        Locationorganization: 'Location of the organization',
        Popularorganizations: 'Popular organizations',
        TourismHolidaysN: 'Tourism, Leisure',
        Rate: 'Rating',
        Thisorganizationfavorites: 'This organization has been saved to your favorites',
        Onlyuserscanremember: 'Only users can remember the organization',
        
        Offer: 'Categories',
        DrrectorNumber: 'Director number',
        Discount: 'Discount',
        CorporateMobile: 'Corporate Mobile',
        Moreinformation: 'More information',

        MyVotes: 'Rated organizations',
        Welcome: 'Welcome',
        BookmarkedData: 'Saved organizations',
        notFound: 'Not found',
        Hide: 'Hide',
        logout: 'Logout',

        pleaseEnterCorectInformation: 'Please enter the data correctly',
        gallery: 'Gallery',
        web: 'Web',
        Activitiesorganization: 'Organization Activity',
        workinghours: 'Working hours',
        menu: 'Menu',
        DeliveryService: 'Delivery Service',
        Legalstatus: 'Legal status',
        Rest: 'Holidays',
        Withoutrest: 'Without holidays',
        Unknown: 'Unknown',
        Director: 'Director',
        Postalcode: 'Postal code',
        IdentificationCode: 'Identification Code',
        Numbers: 'Additional numbers:',
        Dateof: 'Date of establishment',
        Rate: 'Rate',
        Fivestar: 'Very good - 5 stars',
        Forstar: 'Pretty good - 4 stars',
        Threestar: '3 stars',
        Twostar: '2 stars',
        OneStar: '1 stars',
        NoRatefound: 'No rate found',
        similarOrg: 'Similar organizations',
        parentOrg: 'Parent organizations',
        Allcurrencies: 'All currencies',
        Searchphrase: 'Search phrase',
        Authorization: 'Sign in',
        or: 'or',

        Finance: 'Finance',
        ImportExport: 'Import, Export',
        EducationScience: 'Education, Science',
        Buildingrepairs: 'Construction, Repair',
        Production: 'Production',
        CultureArt: 'Culture, Art',
        FarmingLivestock: 'Farming, Livestock',
        CarTransport: 'Car, Transport',
        StateNon: 'State, Non-governmental',
        CommunicationMassMedia: 'Communication, Mass Media',
        Drinks: 'Drinks',
        Yourrating: 'Your rating',
        submit: 'Submit',
        Georgia: 'Georgia',
        Findstreet: 'Find the street',

        Modules: 'Modules',
        Categories: 'Categories',
        Contactus: 'Contact us',
        Downloadapp: 'Download the app',
        ComingSoon: 'Coming Soon',
        serviceunderconstruction: 'Service under construction',

        Company: 'Company',
        AboutUs: 'About Us',
        Direction: 'Direction',
        DirectionTwo: ' Get direction?',
        Contact: 'Contact',
        toseemoreorg: 'To see more organizations, please',

        hi: 'Welcome',
        pleaseAuth: 'Please sign in',
        Moredetails: 'More details',
        CodeC: 'Code',
        commerc: 'Commercial',
        erv: 'National',
        sabaz: 'Basic course',
        beta: 'BETA',

        MainPageTitle: 'Find all kinds of information in one space!',
        MainPageTitleSub: 'Which module information are you looking for?',

        WorkingHours: 'Working hours',
    }
}
export {lang};

// {window.lang.test}