import React from "react";
import { NavLink } from "react-router-dom";

export default function MobileNavigation() {
  return (
    <div>
      <nav className="main__header__navigation">
        <ul className="header__navigation__list">
          <li className="navigation__item__wrapper">
            <NavLink to="/ka-ge/organizations" className="navigation__item">
              <span className="navigation__item__organizations">
                {window.lang.Organizations}
              </span>
            </NavLink>
          </li>
          <li className="navigation__item__wrapper">
            <NavLink to="/ka-ge/events" className="navigation__item">
              <span className="navigation__item__events">
                {window.lang.Afisha}
              </span>
            </NavLink>
          </li>
          <li className="navigation__item__wrapper">
            <NavLink to="/ka-ge/currency" className="navigation__item">
              <span className="navigation__item__currency">
                {window.lang.Currency}
              </span>
            </NavLink>
          </li>
          <li className="navigation__item__wrapper">
            <NavLink to="/ka-ge/streets" className="navigation__item">
              <span className="navigation__item__locations">
                {window.lang.Streets}
              </span>
            </NavLink>
          </li>
          <li className="navigation__item__wrapper">
            <NavLink to="/ka-ge/newcalendar" className="navigation__item">
              <span className="navigation__item__calendar">
                {window.lang.Calendar}
              </span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
