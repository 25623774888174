import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import LoginIcon from "../../../assets/img/icons/reg.svg";
import ProfileIcon from "../../../assets/img/icons/profile.svg";
import logo from "../../../assets/img/icons/logo.svg";
import BackIcon from "../../../assets/img/icons/back-b.svg";

export default function Header({ user, props }) {

  const toggleLanguage = async () => {
    if(localStorage.getItem('locale') == 'ge') {
      localStorage.setItem('locale', 'en');
      await backLanguage('en');
    } else {
      localStorage.setItem('locale', 'ge');
      await backLanguage('ka');
    }
    window.location.reload();
  };

  const backLanguage = async (language) => {
    return fetch(
      `/api/?app=user/SetLang&lang=${language}`
    )
  }

  const browserHistoryBack = () => {
    window.history.back();
  }

  let mainPageUrl = window.location.pathname;

  let isMainPage = true;
  if(mainPageUrl == '/' || mainPageUrl == '/ka-ge/organizations' || mainPageUrl == '/ka-ge/events' || mainPageUrl == '/ka-ge/currency' || mainPageUrl == '/ka-ge/streets' || mainPageUrl == '/ka-ge/newcalendar'){
    isMainPage = true;
  } else {
    isMainPage = false;
  }

  let isUser = typeof user === "object" && user;

  return (
    <header className="main__header">
      {isMainPage ? (
        <div className="language-switch">
            <label className={window.lang.Locale == 'ge' ? `active-lang` : ""}>ქარ</label>
            <label className="switch">
              { window.lang.Locale == 'ge' ? ( 
                  <input type="checkbox" id="switchLocale"/>
              ) : (
                  <input type="checkbox" id="switchLocale" checked />
              )}
              <span
                className="slider round"
                onClick={() => {
                  toggleLanguage();
                }}
              ></span>
            </label>
            <label className={window.lang.Locale == 'en' ? `active-lang` : ""}>ENG</label>
        </div>
        ) : (
          <div className="main__header__navigation__back" onClick={browserHistoryBack}>
            <span className="return-to-back">
              <img src={BackIcon} alt="" />
            </span>
          </div>
        )
      }
      
      <div className="main__header__navigation__logo">
        <NavLink
          to="/"
          className="main__header__navigation__logo__content"
        >
          <img src={logo} width="48" height="48" alt="" />
          <span className="beta-version">{window.lang.beta}</span>
        </NavLink>
      </div>

      <div className="main__header__navigation__register">
        {isUser ? (
          <NavLink to="/ka-ge/user" className="register-link">
            {/* <span className="navigation__item__profile">{user.fname}</span> */}
            <img src={ProfileIcon} alt="login icon"></img>
          </NavLink>
        ) : (
          <NavLink
            className="register-link"
            to='/ka-ge/login'
          >
            <img src={LoginIcon} alt="login icon"></img>
          </NavLink>
        )}
      </div>
    </header>
  );
}
