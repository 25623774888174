import React, { useEffect, useState } from "react";
import GoogleMap from "../../globalParts/GoogleMap/GoogleMap";

import MobileNavigation from "../../globalParts/MobileNavigation/MobileNavigation";
import Message from "../../globalParts/Alerts/Message";
import ReviewItem from "./reviews/ReviewItem";
import SubmitReview from "./reviews/SubmitReview";
import OrganizationItemList from "./OrganizationItemList";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import WithoutImage from '../../../../assets/img/org-no-picture-new.jpg';
import OrganizationIcon from "../../../../assets/img/icons/org.svg";
import OrganizationSmIcon from "../../../../assets/img/icons/org3.png";
import MobileIcon from "../../../../assets/img/icons/phone.svg";
import EmailIcon from "../../../../assets/img/icons/mail.svg";
import LocationIcon from "../../../../assets/img/icons/maps-and-flags.svg";
import DirectionIcon from "../../../../assets/img/icons/get-direction.svg";
import libra from "../../../../assets/img/org/libra.svg";
import mailbox from "../../../../assets/img/org/mailbox.svg";
import docs from "../../../../assets/img/org/docs.svg";
import employee from "../../../../assets/img/org/employee.svg";
import sun from "../../../../assets/img/org/sun.svg";
import calendar from "../../../../assets/img/org/calendar.svg";
import telephone from "../../../../assets/img/org/telephone.svg";
import wallclock from "../../../../assets/img/org/wall-clock.svg";
import WebIco from "../../../../assets/img/icons/globe.svg";
import FacebookIco from "../../../../assets/img/icons/facebook.svg";
import MenuIco from "../../../../assets/img/icons/menu.svg";
import DeliveryIco from "../../../../assets/img/icons/box.svg";
import MoreIco from '../../../../assets/img/icons/more-menu.svg';
import RightArrGreen from '../../../../assets/img/icons/right-arrow.svg'
import BackIcon from "../../../../assets/img/icons/back.svg";
import GalleryIcon from "../../../../assets/img/icons/library.svg";
import ReviewIcon from "../../../../assets/img/icons/review.svg";
import LoveIcon from "../../../../assets/img/icons/heart.svg";
import ShareIcon from "../../../../assets/img/icons/share.svg";

export default function OrganizationPage({ ...props }) {
  const [organization, setOrganization] = useState([]);
  const [organizationActivity, setOrganizationActivity] = useState([]);

  const [voteCount, setVoteCount] = useState(0);
  const [sendReview, toggleSendReview] = useState(false);
  const [userReview, setUserReview] = useState("");

  const [showAllReviews, setShowAllReviews] = useState(false);
  const [reviewItems, setReviewItems] = useState([]);
  const [parentOrganizations, setParentOrganizations] = useState(null);
  const [parentOrganizationsCount, setParentOrganizationsCount] = useState(0);
  const [similarOrganizations, setSimilarOrganizations] = useState(null);
  const [user, setUser] = useState(null);

  const [galleryShow, setGalleryShow] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showWorkingHour, setShowWorkingHour] = useState(false);

  const [isScrolled, setIsScroll] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const [alertStatus, setAlertStatus] = useState(1);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    fetchData();
    fetchParentOrgs();
    fetchSimilarOrgs();
      window.addEventListener('scroll', pageScroll);
    return () => {
      window.removeEventListener("scroll", pageScroll);
    };
  }, [props.match.params.id]);

  const pageScroll = () => {
    if(window.pageYOffset >= 100){
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }
  
  const fetchData = async () => {
    const fetchItem = await fetch(
      `/api/?app=organizations/OrganizationApi&resource=orgById&id=${props.match.params.id}`
    );
    const item = await fetchItem.json();
    setUser(item.user);
    setOrganization(item.data[0]);
    setOrganizationActivity(item);
    setReviewItems(item.data[0].votes);
  };

  const sendReviewFunc = async reviewCount => {
    const item = await fetch(
      `/api/?app=organizations/OrganizationApi&resource=setVote&org_id=${props.match.params.id}&vote=${voteCount}&desc=${userReview}`
    );

    const review = await item.json();
      if(review.status == 1){
        window.location.reload()
      } else {
        setAlertStatus(0)
        setAlertMessage(`${window.lang.pleaseEnterCorectInformation}`);
      }
  };

  const fetchParentOrgs = async () => {
    const fetchItem = await fetch(
      `/api/?app=organizations/OrganizationApi&resource=parent&id=${props.match.params.id}`
    );
    const item = await fetchItem.json();
    setParentOrganizations(item.data);
    setParentOrganizationsCount(item.parentCount);
  };

  const fetchSimilarOrgs = async () => {
    const fetchItem = await fetch(
      `/api/?app=organizations/OrganizationApi&resource=similar&id=${props.match.params.id}`
    );
    const item = await fetchItem.json();

	  setSimilarOrganizations(item.data);
	  window.scrollTo(0, 0);
  };

	const image = organization.logo;
	const images = organization.org_images;
 
	// organization phones
	let resPhones = [];
  const phone_numbers = organization.phones;
  const phone_number = organization.phone;
	let phone = 0;
	let phone_str = 0;
	let resPhoneArr = [];
	
	if( phone_number == '') {
		phone = 0;
	} else {
		phone_str = phone_number && phone_number;
		if(phone_str && phone_str.length == 7) {
			phone = '+99532'+''+phone_str;
		} else if(phone_str && phone_str.length == 9) {
			phone = '+995'+''+phone_str;
		} else {
			phone = phone_str && phone_str;
		}
	}

	for(let i in phone_numbers) {
		resPhones.push(phone_numbers[i]);
	}
	resPhoneArr = resPhones.slice(0, 5)

	// org activity
	let orgActivityRes = [];
	const orgActivity = organizationActivity.data;

	for(let x in orgActivity) {
		orgActivityRes.push(orgActivity[x].activity);
  }
  
  const toggleGallery = async () => {
    setGalleryShow(!galleryShow ? true : false);
  }

  
  const org_open_hour = organization.open_hour;
  let org_open_hour_str = org_open_hour && org_open_hour[0]['htmlData'];
  let org_open_hour_open = org_open_hour && org_open_hour[0]['isOpen'];

  console.log(org_open_hour_open);

  const onBookmarkClick = async(e) => {
    e.preventDefault();
    if(user){
        const fetchItem = await fetch(`/api/?app=organizations/OrganizationApi&resource=setBookmark&org_id=${organization.id}`)
        const item = await fetchItem.json();
        if(item.status == 1) {
            setAlertStatus(1)
            setAlertMessage(`${user.fname} ${window.lang.Thisorganizationfavorites}`);
        } else {
            // alert(`${user.fname} ${window.lang.Thisorganizationfavorites}`);
        }
    } else {
      setAlertStatus(0)
      setAlertMessage(`${window.lang.Onlyuserscanremember}`);
    }
  }

  const browserHistoryBack = () => {
    window.history.back();
  }

  const scrollToGallery = () => {
    window.scrollTo(0, 500);
  }

  const scrollToReview = () => {
    window.scrollTo(0, 950);
  }

  // start rating
	let ActiveStars = Array(organization.rate_score && parseInt(organization.rate_score));
  let InactiveStars =  organization.rate_count == 0 ? Array(5) : Array(organization.rate_score && 5-parseInt(organization.rate_score));
  
  const orgUrlRedirect = (url) => {
    // window.location.href=url;
  }

  let ShareLink = `https://www.08.ge/ka-ge/organization/${organization.id}`;

  // social share
	const ShareBtn = () => {
		window.open(`https://www.facebook.com/sharer/sharer.php?u=${ShareLink}`,'_blank',
			`toolbar=no,
			location=no,
			status=no,
			menubar=no,
			scrollbars=yes,
			resizable=yes,
			width=SomeSize,
			height=SomeSize`);
 		return false;
	}

  return (
    <div className="organization-page__wrapper">

      <div className="organization-page__header">
        <div className={ isScrolled ? 'organization-page__header-navigation active' : 'organization-page__header-navigation' }>
          <div className="organization-page__header-navigation-left">
            <span className="organization-page__header-navigation-item" onClick={browserHistoryBack}><img src={BackIcon} alt="" /></span>
          </div>
          <div className="organization-page__header-navigation-right">
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${ShareLink}`} className="organization-page__header-navigation-item options">
              <img src={ShareIcon} alt="" />
            </a>
            <span className="organization-page__header-navigation-item options" onClick={(e) => {onBookmarkClick(e)}}>
              <img src={LoveIcon} alt="" />
            </span>
            <span className="organization-page__header-navigation-item options" onClick={scrollToReview}>
              <img src={ReviewIcon} alt="" />
            </span>
            {images && images.length >=1 ? (
            <span className="organization-page__header-navigation-item options" onClick={scrollToGallery}>
              <img src={GalleryIcon} alt="" />
            </span>
            ):(
              ''
            )}
          </div>
        </div>
        <div className="organization-page_header-img-wrapper" style={{backgroundImage: `url(` + (image ? 'https://cdn.08.ge/logos/' + image : WithoutImage) + ')'}}>
        </div>
      </div>
     
      <div className="organization-page__second-mid__wrapper">
        <div className="organization-page__info__wrapper">
          <div className="organization-page__profile">
            <div className="organization-page__profile__content">
              <div className="organization__header__wrapper organization__header__wrapper__single">
                <div className="organization-name_wrapper">
                  <img
                    src={
                      organization.parent == 0
                        ? OrganizationIcon
                        : OrganizationSmIcon
                    }
                    alt=""
                  />
                  <div
                    className="organization-name single-organization-name"
                    title={organization.name}
                  >
                    {organization ? organization.name : "Organization Name"}
                  </div>
                </div>
                <div className="organization-profile-working-hours__wrapper" onClick={e => {setShowWorkingHour(!showWorkingHour)}}>
                  <span className="working-hours-header">
                    <img src={wallclock} />
                    {window.lang.workinghours}:{" "} {organization.work_hours}
                  </span>
                  {  org_open_hour_open == 1 || org_open_hour_open == 0 ? (
                    <span>
                      <img src={RightArrGreen} alt="" className={showWorkingHour ? 'down-arrow-green' : 'right-arrow-green'} />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                {
                !showWorkingHour ? (
                    ''
                ) : (
                  org_open_hour_open == 1 || org_open_hour_open == 0 ? (
                    <div className="organization-open-hour-content organization-open-hour-content__single">
                      {/* <div className="organization-page__open-hour-head-title">
                        <span>{window.lang.WorkingHours}</span>
                      </div> */}
                      <div className="working-hour-wrapper" dangerouslySetInnerHTML={{ __html: org_open_hour_str }}></div>
                    </div>
                  ) : (
                      ''
                  )
                )}
              </div>
            </div>

            <div className="organization-profile-contact">
              <div className="organization-profile-mobile">
                <img src={MobileIcon} alt="" />
                <a href={`tel:${phone}`}>
                  <span>
										{phone ? phone : window.lang.Hide}
									</span>
                </a>
              </div>
              <div className="organization-profile-webpage">
                {organization.web_address ? (
                  <a
                    href="#"
                    href={`//${organization.web_address}`}>
                    <img src={WebIco} alt="" />
                  </a>
                ) : (
                  ''  
                )}
              </div>
              {organization.email_address && (
                <div className="organization-profile-email">
                  <a href={`mailto:${organization.email_address}`}>
                    <img src={EmailIcon} alt="" />
                  </a>
                </div>
              )}
              {organization.social_address && (
                <div className="organization-profile-facebook">
                  <a href={`http://`+organization.social_address}>
                    <img src={FacebookIco} alt="" />
                  </a>
                </div>
							)}
            </div>

            <div className="organization-description_wrapper">
              <div className="organization-description-label">
                {window.lang.Activitiesorganization}
              </div>
              <div className="organization-page-paragraph">
                {orgActivityRes.join(", ")}
              </div>
            </div>
            
            <div className='see-org-details--btn' onClick={e => {setShowMoreInfo(!showMoreInfo)}}>
              <img src={MoreIco} alt="" className='more-icon'/>
              <span>{window.lang.Moredetails}</span>
              <img src={RightArrGreen} alt="" className={showMoreInfo ? 'down-arrow-green' : 'right-arrow-green'} />
            </div>

            {
              !showMoreInfo ? (
                  ''
              ) : (
              <div className="organization-page__info">
                <div className="organization-page__info__blocks">
                  <div className="organization-page__info__content">
                    <span className="info-header">
                      <img src={libra} />
                      {window.lang.Legalstatus}:{" "}
                    </span>
                    <div className="corresponding-info__wrapper">
                      <span className="corresponding-info">
                        {organization.legal_status}
                      </span>
                    </div>
                  </div>
                  <div className="organization-page__info__content">
                      <span className="info-header">
                        <img src={employee} />
                        {window.lang.Director}:{" "}
                      </span>
                      <div className="corresponding-info__wrapper">
                        <span className="corresponding-info">
                          {organization.chief}
                        </span>
                      </div>
                    </div>
                </div>
               
                <div className="organization-page__info__blocks">
                  <div className="organization-page__info__content">
                    <span className="info-header">
                      <img src={docs} />
                      {window.lang.IdentificationCode}:{" "}
                    </span>
                    <div className="corresponding-info__wrapper">
                      <span className="corresponding-info">
                        {organization.ident_code}
                      </span>
                    </div>
                  </div>
                  <div className="organization-page__info__content">
                    <span className="info-header">
                      <img src={sun} />
                      {window.lang.Rest}:{" "}
                    </span>
                    <div className="corresponding-info__wrapper">
                      <span className="corresponding-info">
                      {
												organization.day_offs == 0 ? (
													window.lang.Withoutrest
												) : organization.day_offs == '' ? (
													window.lang.Unknown
												) : (
													organization.day_offs
												)
											}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="organization-page__info__blocks">
                 
                <div className="organization-page__info__content">
                    <span className="info-header">
                      <img src={mailbox} />
                      {window.lang.Postalcode}:{" "}
                    </span>
                    <div className="corresponding-info__wrapper">
                      <span className="corresponding-info">
                        {" "}
                        {organization.organization_index}{" "}
                      </span>
                    </div>
                  </div>

                  <div className="organization-page__info__content">
                    <span className="info-header">
                      <img src={calendar} />
                      {window.lang.Dateof}:{" "}
                    </span>
                    <div className="corresponding-info__wrapper">
                      <span className="corresponding-info">
                        {organization.found_date}
                      </span>
                    </div>
                  </div>
                </div>
                {resPhoneArr.length <= 1 ? 
							  ( '' ) : (  
                <div className="organization-page__info__blocks">
                  <div className="organization-page__info__content organization-page__info__content__mobile" style={{ width: "100%" }}>
                    <span className="info-header"><img src={telephone} />{window.lang.Numbers} </span>
                    <div className="corresponding-info__wrapper">
                      <span className="corresponding-info">
                      {resPhoneArr.map((e, index) => {
												return (
													<span key={index}>{window.lang.Locale == 'ge' ? e.department + ':' : ''} {e.phone_number}<br/></span>
												);
											})}
                      </span>
                    </div>
                  </div>
                </div>
                )}
              </div>
            )}
            
          </div>
          
          {/* {org_open_hour_open == 1 || org_open_hour_open == 0 ? (
            <div className="organization-open-hour-content organization-open-hour-content__single">
              <div className="organization-page__open-hour-head-title">
                <span>{window.lang.WorkingHours}</span>
              </div>
              <div className="working-hour-wrapper" dangerouslySetInnerHTML={{ __html: org_open_hour_str }}></div>
            </div>
          ) : (
              ''
          )} */}

          <div className="organization-rating-content organization-rating-content__single">
              <div className="organization-rating">
                {organization.rate_count} {window.lang.Rate}
              </div>
							<div className="organization-rating-stars">
								{ organization.rate_count == 0 ? (
									''
								) : (
									Array.from(ActiveStars, (e, index) => {
										return (
											<span key={index}>★</span>
										)
									})
								)}
															
								{
									Array.from(InactiveStars, (e, index) => {
										return (
											<span key={index} className="star_dis">★</span>
										)
									})
								}					
							</div>
          </div>

          {(organization.has_delivery === 1 ||
              organization.has_menu === 1) && (
              <div className="organization-service-info_wrapper">
                <a href="#" className="organization-service-menu">
                  <img src={MenuIco} alt="" />
                  <span>{window.lang.menu}</span>
                </a>
                <div className="organization-service-line">
                  <div className="vertical-line"></div>
                </div>
                <div className="organization-service-delivery">
                  <img src={DeliveryIco} alt="" />
                  <span>{window.lang.DeliveryService}</span>
                </div>
            </div>
          )}

          {images && images.length >=1 ? (
            <div className="organization-page__gallery__wrapper" id='org-gallery' name='org-gallery'>
              <Carousel responsive={responsive}>
                {images && images.map((img) => {
                    return (
                      <img width="100%" height="100%" key={img.id} src={`https://cdn.08.ge/gallery/${img.img}`} />
                    );
                })}
              </Carousel>
            </div>
          ) : (
            ''
          )}

          <div className="organizaion-profile-location__wrapper">
            <div className="organization-profile-direction">
              <div className="organization-profile-direction-list">
                <img src={LocationIcon} alt="" />
                <span>{organization.address}</span>
              </div>
            </div>
            <div className="google-map-content">
              <div className="organization-profile-location">
                <img src={DirectionIcon} alt="" />
                <a href={`https://www.google.com/maps/search/?api=1&query=${organization.latitude},${organization.longitude}`} target="_blank" >{window.lang.DirectionTwo}</a>
              </div>
              {organization && <GoogleMap organizations={organization} />}
            </div>
            {/* <div className="organization-profile-direction">
              <div className="organization-profile-direction-list">
                <img src={LocationIcon} alt="" />
                <span>{organization.address}</span>
              </div>
            </div> */}
          </div>

        </div>
      </div>

      <div className="organization-page__similar-orgs__wrapper">
        <div className="organization-page_review_wrapper">
          <SubmitReview
            setVoteCount={setVoteCount}
            voteCount={voteCount}
            setUserReview={setUserReview}
            sendReviewFunc={() => sendReviewFunc()}
          />
          <div className="organization-page_review_inner">
          {reviewItems &&
            reviewItems.map((e, index) => {
            return <ReviewItem key={index} review={e} />;
            })}
            {reviewItems.length < 1 ? ( 
              <div className="empty-reviews">
                <p>{window.lang.NoRatefound}</p>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        { parentOrganizations && parentOrganizations.length ? (
          <div className="organization-page__similar-orgs">
            <div className="organization-page__similar-head-title">
              <span>{window.lang.parentOrg}</span>
            </div>
            {parentOrganizations &&
                parentOrganizations.map((element, index) => {
                  return (
                    <OrganizationItemList key={index} organization={element} />
                  );
                })}
          </div>
				) : (
					''
				)}

        <div className="organization-page__similar-orgs">
          <div className="organization-page__similar-head-title">
            <span>{window.lang.similarOrg}</span>
          </div>
          {similarOrganizations &&
              similarOrganizations.map((element, index) => {
                return (
                  <OrganizationItemList key={index} organization={element} />
                );
              })}
        </div>

      </div>
      <Message message={alertMessage} status={alertStatus} />
      <MobileNavigation />
    </div>
  );
}
